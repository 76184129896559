<template>
  <b-card>
    <h4 style="padding-bottom: 15px;">
      Other Resources
    </h4>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center vertical-line"
      >
        <div class="top-btn">
          <b-button
            :href="getYoutubeURL"
            target="_blank"
            class="custom-button"
            variant="danger"
            style="margin-bottom:1px;"
          >
            <feather-icon
              icon="YoutubeIcon"
            />
            YouTube
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom.v-primary="'URL link can be copied by clicking the copy link icon!'"
          variant="outline-none"
          class="custom-button"
          style="margin-top: 10px;"
          @click="copyYoutubeURL"
        >
          <feather-icon icon="LinkIcon" />
          Copy URL
        </b-button>
      </b-col>
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="top-btn">
          <b-button
            :href="getUserGuideURL"
            target="_blank"
            variant="primary"
            class="custom-button"
            style="margin-bottom:1px;"
          >
            User Guide
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom.v-primary="'URL link can be copied by clicking the copy link icon!'"
          variant="gradient-none"
          class="custom-button"
          style="margin-top: 10px;"
          @click="copyDocURL"
        >
          <feather-icon icon="LinkIcon" />
          Copy URL
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  youtubeResourceUrl, userGuideUrl,
} from '@/constants/config'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [SuccessMessage, ErrorMessage],

  data() {
    return {
      youtubeResourceUrl,
      userGuideUrl,
    }
  },

  computed: {
    getYoutubeURL() {
      return youtubeResourceUrl
    },
    getUserGuideURL() {
      return userGuideUrl
    },
  },

  methods: {

    // create common copyURL method to access both copyYoutubeURL and copyDocURL methods
    copyURL(urlText) {
      try {
        const textarea = document.createElement('textarea')
        textarea.value = urlText
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        this.showSuccessMessage('Link has been copied successfully')
      } catch (error) {
        this.showErrorMessage('Failed to copy link')
      }
    },

    // copyYoutubeURL method
    copyYoutubeURL() {
      this.copyURL(this.youtubeResourceUrl)
    },

    // copyYoutubeURL method
    copyDocURL() {
      this.copyURL(this.userGuideUrl)
    },
  },
}

</script>

<style>
.vertical-line {
    border-right: 3px solid #ccc;
    padding-right: 15px;
}

.custom-button {
    margin-top: 20px;
    margin-left: 60px;

}

.purple-button {
  background-color: #7366f0; /* Use the Vuexy template's purple color code */
  color: #fff; /* Set the text color to white for better contrast */
  /* Add any other custom styles as needed */
}
</style>
